<template>
  <div class="container" id="segmentationRelations_create">
    <h1 v-html="$t('segmentation_relations_create_title')"></h1>

    <v-form @submit.prevent="onSubmit()">
      <!-- Ablieferung durch! -->
      <h2 v-html="$t('segmentation_relations_delivery_to')"></h2>
      <v-row>
        <v-col cols="3" sm="6" md="3">
          <v-text-field
            id="dairyOutIdent"
            :loading="loadingOut"
            v-model="segmentationRelation.dairyOut.ident"
            type="number"
            :label="$t('segmentation_relations_dairy_ident')"
            @blur="loadDairyOut(segmentationRelation.dairyOut.ident)"
          />
        </v-col>
        <v-col cols="3" sm="6" md="3"> {{ errorMessageOut }} </v-col>
        <v-col cols="3" sm="6" md="3">
          {{ segmentationRelation.dairyOut.name }}
        </v-col>
      </v-row>

      <!-- Zukauf durch -->
      <h2 v-html="$t('segmentation_relations_additional_purchase')"></h2>
      <v-row>
        <v-col cols="3" sm="6" md="3">
          <v-text-field
            id="dairyInIdent"
            :loading="loadingIn"
            v-model="segmentationRelation.dairyIn.ident"
            type="number"
            :label="$t('segmentation_relations_dairy_ident')"
            @blur="loadDairyIn(segmentationRelation.dairyIn.ident)"
          />
        </v-col>
        <v-col cols="3" sm="6" md="3"> {{ errorMessageIn }} </v-col>
        <v-col cols="3" sm="6" md="3">
          {{ segmentationRelation.dairyIn.name }}
        </v-col>
      </v-row>

      <!-- von -->
      <h2 v-html="$t('segmentation_relations_from')"></h2>
      <v-row>
        <v-col cols="3" sm="6" md="3">
          <DbmDatePicker v-model="segmentationRelation.validFrom" :dateLabel="$t('segmentation_relations_from')" />
        </v-col>
      </v-row>

      <!-- bis -->
      <h2 v-html="$t('segmentation_relations_until')"></h2>
      <v-row>
        <v-col cols="3" sm="6" md="3">
          <DbmDatePicker v-model="segmentationRelation.validUntil" :dateLabel="$t('segmentation_relations_until')" :clearable="true" />
        </v-col>
      </v-row>

      <!-- Fütterung -->
      <h2 v-html="$t('search_segmentation_relations_feeding')"></h2>
      <v-row>
        <v-col cols="3" sm="6" md="3">
          <v-select
            id="feeding"
            v-model="segmentationRelation.feeding"
            :label="$t('search_segmentation_relations_feeding')"
            :items="feedings"
            item-title="title"
            item-value="value"
            clearable
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="3" sm="6" md="3">
          <v-btn color="primary" :disabled="checkIfIsDisabled()" type="submit"><span v-html="$t('segmentation_relations_save')" /></v-btn>
          <v-btn color="secondary" @click="goBack()"><span v-html="$t('back')" /></v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<script lang="ts">
import { showError } from '@/services/axios'
import { apiURL, emitter } from '@/main'
import DbmDatePicker from '@/components/dbmDatePicker.vue'

const segmentationRelationMinimal = {
  dairyIn: {
    id: null,
    ident: null
  },
  dairyOut: {
    id: null,
    ident: null
  },
  validFrom: '2021-01-01',
  validUntil: '',
  feeding: 0
}

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'segmentationRelations_create',
  data() {
    return {
      feedings: [
        {
          value: 'NO_SILAGE',
          title: this.$t('segmentation_relations_no_silage')
        },
        { value: 'SILAGE', title: this.$t('segmentation_relations_silage') }
      ],
      segmentationRelation: segmentationRelationMinimal,
      errorMessageIn: '',
      errorMessageOut: '',
      loadingIn: false,
      loadingOut: false
    }
  },
  components: {
    DbmDatePicker: DbmDatePicker
  },
  methods: {
    async onSubmit() {
      try {
        const result = await this.axios.post(
          apiURL + '/segmentationRelations/',
          {
            ...this.segmentationRelation
          },
          { headers: { 'Content-Type': 'application/json' } }
        )
        emitter.emit('toastSuccess', this.$t('toast_save_success_message'))
      } catch (e) {
        showError(e)
      }
    },
    createBeginningDate() {
      const currentDate = new Date()
      var currentYear = currentDate.getFullYear().toString()
      return `${currentYear}-01-01`
    },
    goBack() {
      this.$router.push({ name: 'segmentationRelations_read' }).catch(() => {
        /* duplicated route */
      })
    },
    async loadDairyIn(ident) {
      if (ident) {
        try {
          this.loadingIn = true
          const response = await this.axios.get(
            apiURL + '/dairies/findByIdent',
            {
              params: { ident: ident }
            },
            { headers: { 'Content-Type': 'application/json' } }
          )
          if (response.data) {
            this.segmentationRelation.dairyIn.id = response.data.id
            this.segmentationRelation.dairyIn.name = response.data.name
            this.errorMessageIn = ''
          } else {
            this.segmentationRelation.dairyIn.id = undefined
            this.segmentationRelation.dairyIn.name = ''
            this.errorMessageIn = this.$t('segmentation_relations_no_such_dairy')
          }
        } catch (e) {
          showError(e)
        } finally {
          this.loadingIn = false
        }
      } else if (ident == '' || ident == null) {
        this.segmentationRelation.dairyIn.id = undefined
        this.segmentationRelation.dairyIn.name = ' '
        this.errorMessageIn = ' '
      }
    },
    async loadDairyOut(ident) {
      if (ident) {
        try {
          this.loadingOut = true
          const response = await this.axios.get(
            apiURL + '/dairies/findByIdent',
            {
              params: { ident: ident }
            },
            { headers: { 'Content-Type': 'application/json' } }
          )
          if (response.data) {
            this.segmentationRelation.dairyOut.id = response.data.id
            this.segmentationRelation.dairyOut.name = response.data.name
            this.errorMessageOut = ''
          } else {
            this.segmentationRelation.dairyOut.id = undefined
            this.segmentationRelation.dairyOut.name = ''
            this.errorMessageOut = this.$t('segmentation_relations_no_such_dairy')
          }
        } catch (e) {
          showError(e)
        } finally {
          this.loadingOut = false
        }
      } else if (ident == '' || ident == null) {
        this.segmentationRelation.dairyOut.id = undefined
        this.segmentationRelation.dairyOut.name = ' '
        this.errorMessageOut = ' '
      }
    },
    checkIfIsDisabled() {
      if (
        this.segmentationRelation.dairyIn.id == null ||
        this.segmentationRelation.dairyIn.id == undefined ||
        this.segmentationRelation.dairyOut.id == null ||
        this.segmentationRelation.dairyOut.id == undefined
      )
        return true
      else return false
    }
  },
  mounted() {
    this.segmentationRelation.validFrom = this.createBeginningDate()
  }
})
</script>
